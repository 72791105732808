import { psconnectionStart, psconnectionSuccess, psconnectionError } from '../features/web3/presaleSlice'
import Web3 from 'web3'
import info from '../info.json'
import { presaleAbi } from '../abi/presaleAbi'
import BigNumber from 'bignumber.js'


const web3 = new Web3(info.readOnlyApi)
const presaleContract = new web3.eth.Contract(presaleAbi, info.contractAddress);

async function removeDecimal(balance, decimals) {
    const bn = new BigNumber(balance + "e-" + decimals);
    const strBig = bn.toString();
    const finalresult = convert(strBig);
    return finalresult.toString();
}

function convert(n) {
    var sign = +n < 0 ? "-" : "",
        toStr = n.toString();
    if (!/e/i.test(toStr)) {
        return n;
    }
    var [lead, decimal, pow] = n.toString()
        .replace(/^-/, "")
        .replace(/^([0-9]+)(e.*)/, "$1.$2")
        .split(/e|\./);
    return +pow < 0
        ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
        : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
}

export const updatePresale = async (presale, dispatch) => {

    dispatch(psconnectionStart());
    try {
        const weiRaised = await presaleContract.methods.weiRaised().call();
        const rate = await presaleContract.methods.rate().call();
        const refPercent = await presaleContract.methods.refpercent().call();
        const tokenAddress = await presaleContract.methods.token().call();
        const rateWD = await removeDecimal(rate, "9")
        var refTrue;
        console.log(weiRaised, rate)
        if (Number(refPercent) > 0) {
            refTrue = true;
        } else { refTrue = false }
        const presaleInfo = {
            weiRaised: weiRaised,
            rate: rateWD,
            refpercent: refPercent,
            token: tokenAddress,
            refBool: refTrue,
        }
        dispatch(psconnectionSuccess(presaleInfo))
    } catch (error) {
        dispatch(psconnectionError());
        console.log(error)
    }
}