import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateWallet } from "./app/connectWeb3";
import { updatePresale } from "./app/connectPresale";

import Header from "./components/Header";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { abi, byte } from "./components/lockabibyte";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";

//purple #41015D
//yellow #E7B655

function App() {
  const dispatch = useDispatch();
  const { web3g, pending, error } = useSelector((state) => state.wallet);
  const { pStats, pspending, pserror } = useSelector((state) => state.presale);
  const [fee, setfee] = useState("0");

  useEffect(() => {
    if (web3g.connected) {
      async function getAddress() {
        web3g.provider.on("accountsChanged", (accounts) => {
          updateWallet({}, dispatch);
        });
        web3g.provider.on("chainChanged", (_chainId) => {
          updateWallet({}, dispatch);
          console.log("chain");
        });
      }
      getAddress();
    }
  }, [web3g]);

  useEffect(() => {
    if (!pspending) {
      updatePresale({}, dispatch);
    }
  }, []);

  const handleDeploy = async (e) => {
    e.preventDefault();
    const contract = new web3g.web3.eth.Contract(abi);
    try {
      const gas = await contract
        .deploy({
          data: "0x" + byte,
          arguments: [web3g.web3.utils.toWei(fee)],
        })
        .estimateGas({ from: web3g.walletAddress });

      console.log(gas);
      await contract
        .deploy({
          data: "0x" + byte,
          arguments: [web3g.web3.utils.toWei(fee)],
        })
        .send(
          {
            from: web3g.walletAddress,
            gasLimit: gas,
          },
          function (error, transactionHash) {
            console.log(error);
          }
        );
    } catch (error) {
      console.log(`Transaction failed: ${error}`);
    }
  };

  return (
    <>
      <ToastContainer />
      <Header />
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-12">
            <Form.Label>
              This is commission value for lock for 0.01 Eth you need to enter
              0.01 no need to add zero's
            </Form.Label>
            <Form.Control
              size="lg"
              type="text"
              value={fee}
              name="erc20commission"
              onChange={(e) => setfee(e.target.value)}
            ></Form.Control>
            <hr className="mb-3 mt-3" />

            <button
              className="btn btn-primary"
              onClick={(e) => {
                web3g.connected
                  ? handleDeploy(e)
                  : toast.error("please connect wallet");
              }}
            >
              Deploy
            </button>
          </div>
        </div>
      </div>

      {/* Same as */}
    </>
  );
}

export default App;
