import React from "react";
import "../App.css";
import Loading from "./Loading";
import { useDispatch, useSelector } from "react-redux";
import { updateWallet } from "../app/connectWeb3";

function Header() {
  const dispatch = useDispatch();
  const { web3g, pending, error } = useSelector((state) => state.wallet);
  const addressShort =
    web3g.walletAddress.substring(0, 4) +
    "..." +
    web3g.walletAddress.substring(38, 42);
  return (
    <>
      <div className="nav">
        <div className="logoSide">Deploy Contract</div>
        <div className="buttonSide">
          <ul className="menuList">
            <li className="menuEl">
              <button
                className="button-33"
                onClick={() => !web3g.connected && updateWallet({}, dispatch)}
              >
                {pending ? (
                  <Loading />
                ) : web3g.connected ? (
                  addressShort
                ) : (
                  "Connect"
                )}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Header;
